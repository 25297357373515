var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "wechat-title",
          rawName: "v-wechat-title",
          value: (_vm.$route.meta.title = _vm.userInfos.propName),
          expression: "$route.meta.title=userInfos.propName"
        }
      ],
      staticClass: "index",
      attrs: { id: "index" }
    },
    [
      _vm.userInfos.propName
        ? [
            _c("div", { staticClass: "propertyBox" }, [
              _c("div", { staticClass: "projectName" }, [
                _c(
                  "div",
                  { staticClass: "headImg", on: { click: _vm.accountEvent } },
                  [
                    _vm.userInfos.head == null || _vm.userInfos.head == ""
                      ? _c("img", {
                          attrs: {
                            src: require("../../assets/images/img_default1.png")
                          }
                        })
                      : _c("img", { attrs: { src: _vm.userInfos.head } }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "max-width": "10em",
                          "word-break": "break-word"
                        }
                      },
                      [_vm._v(_vm._s(_vm.userInfos.name))]
                    )
                  ]
                ),
                _c("div", { staticClass: "headInfo" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.userInfos.propName))]),
                  _c("p", [
                    _vm._v(_vm._s(_vm._f("installAdressFormat")(_vm.userInfos)))
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "selectProperty",
                  on: { click: _vm.switchProperty }
                },
                [_vm._v("切换房号")]
              )
            ]),
            _c("div", { staticClass: "navBox" }, [
              _c(
                "div",
                {
                  staticClass: "unitBox charge",
                  on: { click: _vm.chargeEvent }
                },
                [
                  _c("div", { staticClass: "imgBox" }),
                  _c("p", [_vm._v("账单缴费")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "unitBox bill",
                  on: { click: _vm.historyBillEvent }
                },
                [
                  _c("div", { staticClass: "imgBox" }),
                  _c("p", [_vm._v("充值记录")])
                ]
              ),
              _c(
                "div",
                { staticClass: "unitBox alarm", on: { click: _vm.alarmEvent } },
                [
                  _c("div", { staticClass: "imgBox" }, [
                    _vm.alarmLength > 0
                      ? _c("span", [_vm._v(_vm._s(_vm.alarmLength))])
                      : _vm._e()
                  ]),
                  _c("p", [_vm._v("告警记录")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "unitBox chart",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/chart")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "imgBox" }),
                  _c("p", [_vm._v("用电情况")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "unitBox message",
                  on: { click: _vm.messageEvent }
                },
                [
                  _c("div", { staticClass: "imgBox" }),
                  _c("p", [_vm._v("短信设置")])
                ]
              )
            ]),
            _vm.alarmList.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "alarmBox",
                    class: { marquee_top: _vm.animate },
                    on: { click: _vm.alarmEvent }
                  },
                  [
                    _c("div", { staticClass: "marqueeBox" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "marquee_list",
                          class: { marquee_top: _vm.animate }
                        },
                        _vm._l(_vm.alarmList, function(item) {
                          return _c(
                            "li",
                            {
                              key: item.index,
                              on: { mouseover: _vm.stop, mouseout: _vm.start }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.happenTime.slice(0, -3))
                              ),
                              _c("span", { staticClass: "inline" }, [
                                _vm._v(_vm._s(item.alarmName))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._m(0),
            _c("div", { staticClass: "meterTab" }, [
              _c(
                "span",
                {
                  class: { active: _vm.meterElectIs },
                  on: {
                    click: function($event) {
                      _vm.meterElectIs = true
                    }
                  }
                },
                [_vm._v("当前电表")]
              ),
              _c(
                "span",
                {
                  class: { active: !_vm.meterElectIs },
                  on: {
                    click: function($event) {
                      _vm.meterElectIs = false
                    }
                  }
                },
                [_vm._v("当前水表")]
              )
            ]),
            _vm.meterElectIs
              ? _c("div", { staticClass: "meterListBox" }, [
                  _vm.metersList.electricMeters.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.metersList.electricMeters, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "swiper-slider" },
                            [
                              _c("div", { staticClass: "time" }, [
                                _vm._v("电表：" + _vm._s(item.meterAddr) + " "),
                                item.currentReadTime !== null &&
                                item.currentReadTime !== ""
                                  ? _c("span", [
                                      _vm._v(
                                        "时间:" +
                                          _vm._s(
                                            item.currentReadTime.slice(0, -3)
                                          )
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "address" }, [
                                _vm._v(_vm._s(item.roomName))
                              ]),
                              _c("div", { staticClass: "valueBox" }, [
                                _c("div", { staticClass: "price" }, [
                                  _vm._m(1, true),
                                  item.chargeKind == 1
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "number",
                                          class: {
                                            reder:
                                              item.balance <
                                              item.balanceNotEnough
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatMoneyThousand")(
                                                item.balance
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _c("p", { staticClass: "number" }, [
                                        _vm._v("--")
                                      ])
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  _c("p", { staticClass: "info" }, [
                                    _vm._v("当前行度")
                                  ]),
                                  _c("p", { staticClass: "number" }, [
                                    _vm._v(_vm._s(item.currentValue))
                                  ])
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  _c("p", { staticClass: "info" }, [
                                    _vm._v("本月用电")
                                  ]),
                                  _c("p", { staticClass: "number" }, [
                                    _vm._v(_vm._s(item.monthUseValue))
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "btnArea" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btner",
                                    on: {
                                      click: function($event) {
                                        return _vm.electDetail(item)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("查看详情 ")])]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btner yellow",
                                    on: {
                                      click: function($event) {
                                        return _vm.chartEvent(item)
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("用电情况 ")])]
                                ),
                                item.chargeKind == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "btner green",
                                        on: {
                                          click: function($event) {
                                            return _vm.electRecharge(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", { staticClass: "green" }, [
                                          _vm._v("立即充值 ")
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "noMeter" }, [
                        _vm._v(" 当前房间无电表! ")
                      ])
                ])
              : _c(
                  "div",
                  { staticClass: "meterListBox" },
                  [
                    _vm._l(_vm.metersList.waterMeters, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "swiper-slider" },
                        [
                          _c("div", { staticClass: "time" }, [
                            _vm._v("水表：" + _vm._s(item.meterAddr)),
                            item.currentReadTime !== null
                              ? _c("span", [
                                  _vm._v(
                                    "时间:" +
                                      _vm._s(item.currentReadTime.slice(0, -3))
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "address" }, [
                            _vm._v(_vm._s(item.roomName))
                          ]),
                          _c("div", { staticClass: "valueBox" }, [
                            _c("div", { staticClass: "price" }, [
                              _c("p", { staticClass: "info" }, [
                                _vm._v("当前行度")
                              ]),
                              _c("p", { staticClass: "number" }, [
                                _vm._v(_vm._s(item.currentValue))
                              ])
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _c("p", { staticClass: "info" }, [
                                _vm._v("本月用水")
                              ]),
                              _c("p", { staticClass: "number" }, [
                                _vm._v(_vm._s(item.monthUseValue))
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "btnArea" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btner",
                                on: {
                                  click: function($event) {
                                    return _vm.waterDetail(item)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("查看详情 ")])]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btner yellow",
                                on: {
                                  click: function($event) {
                                    return _vm.chartWaterEvent(item)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("用水情况 ")])]
                            )
                          ])
                        ]
                      )
                    }),
                    (_vm.metersList.waterMeters &&
                      _vm.metersList.waterMeters.length == 0) ||
                    _vm.metersList.waterMeters == null
                      ? _c("div", { staticClass: "noMeter" }, [
                          _vm._v(" 当前房间无水表! ")
                        ])
                      : _vm._e()
                  ],
                  2
                ),
            _vm._m(2),
            _c(
              "div",
              { staticClass: "billTotalInfo", on: { click: _vm.chargeEvent } },
              [
                _vm._m(3),
                _c("h4", [
                  _vm._v(
                    "房号：" +
                      _vm._s(_vm._f("installAdressFormat")(_vm.userInfos))
                  )
                ]),
                _c("div", { staticClass: "billInfoS" }, [
                  _c("div", { staticClass: "billUn" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("未缴账单")]),
                    _c("p", { staticClass: "number" }, [
                      _vm._v(_vm._s(_vm.unpaidBillMode.several) + "张")
                    ])
                  ]),
                  _c("div", { staticClass: "billUn" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("已逾期合计")]),
                    _c("p", { staticClass: "number" }, [
                      _vm._v("￥" + _vm._s(_vm.unpaidBillMode.overdueAmount))
                    ])
                  ]),
                  _c("div", { staticClass: "billUn" }, [
                    _c("p", { staticClass: "label" }, [_vm._v("未缴合计")]),
                    _c("p", { staticClass: "number" }, [
                      _vm._v("￥" + _vm._s(_vm.unpaidBillMode.notPaidAmount))
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "van-action-sheet",
              {
                staticClass: "indexDetail",
                attrs: { title: "电表详情", "close-icon": "cross" },
                model: {
                  value: _vm.electDetailVis,
                  callback: function($$v) {
                    _vm.electDetailVis = $$v
                  },
                  expression: "electDetailVis"
                }
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("表")]),
                      _c("span", [_vm._v("计")]),
                      _c("span", [_vm._v("表")]),
                      _c("span", [_vm._v("号")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v(_vm._s(_vm.electDetailMode.meterAddr))
                      ]),
                      _c("br"),
                      _c("p", [_vm._v(_vm._s(_vm.electDetailMode.roomName))])
                    ])
                  ]),
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("当")]),
                      _c("span", [_vm._v("前")]),
                      _c("span", [_vm._v("行")]),
                      _c("span", [_vm._v("度")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v(_vm._s(_vm.electDetailMode.currentValue))
                      ]),
                      _vm._v(
                        "(" + _vm._s(_vm.electDetailMode.currentReadTime) + ")"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("当")]),
                      _c("span", [_vm._v("前")]),
                      _c("span", [_vm._v("余")]),
                      _c("span", [_vm._v("额")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v("￥" + _vm._s(_vm.electDetailMode.balance))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("计")]),
                      _c("span", [_vm._v("价")]),
                      _c("span", [_vm._v("模")]),
                      _c("span", [_vm._v("式")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _vm.electDetailMode.electricPriceVO
                        ? _c("span", { staticClass: "blue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.valuationElectEnum[
                                  _vm.electDetailMode.electricPriceVO
                                    .valuationKind
                                ]
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.electDetailMode.electricPriceVO &&
                      _vm.electDetailMode.electricPriceVO.valuationKind == 1
                        ? _c("span", { staticClass: "blue" }, [
                            _vm._v(
                              "（￥" +
                                _vm._s(
                                  _vm.electDetailMode.electricPriceVO
                                    .fixedPriceVO.price
                                ) +
                                "/度）"
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm.electDetailMode.electricPriceVO &&
                  _vm.electDetailMode.electricPriceVO.valuationKind == 2
                    ? _c("div", { staticClass: "detailUnit" }, [
                        _c("div", { staticClass: "text sp" }, [
                          _c("div", { staticClass: "bold" }, [
                            _vm._v(
                              "尖:￥" +
                                _vm._s(
                                  _vm.electDetailMode.electricPriceVO
                                    .timeSharingVO.tip
                                ) +
                                "; 峰:￥" +
                                _vm._s(
                                  _vm.electDetailMode.electricPriceVO
                                    .timeSharingVO.peak
                                ) +
                                "; 平:￥" +
                                _vm._s(
                                  _vm.electDetailMode.electricPriceVO
                                    .timeSharingVO.flat
                                ) +
                                "; 谷:￥" +
                                _vm._s(
                                  _vm.electDetailMode.electricPriceVO
                                    .timeSharingVO.ancient
                                )
                            )
                          ]),
                          _vm.electDetailMode.electricPriceVO.timeSharingVO
                            .sharing.length < 4
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO
                                      .timeSharingVO.sharing,
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start) +
                                              "-" +
                                              _vm._s(item.end) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.electDetailMode.electricPriceVO.timeSharingVO
                            .sharing.length > 3 &&
                          _vm.electDetailMode.electricPriceVO.timeSharingVO
                            .sharing.length < 7
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(
                                      0,
                                      3
                                    ),
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start.slice(0, -3)) +
                                              "-" +
                                              _vm._s(item.end.slice(0, -3)) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(
                                      3,
                                      6
                                    ),
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start.slice(0, -3)) +
                                              "-" +
                                              _vm._s(item.end.slice(0, -3)) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.electDetailMode.electricPriceVO.timeSharingVO
                            .sharing.length > 6
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(
                                      0,
                                      3
                                    ),
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start.slice(0, -3)) +
                                              "-" +
                                              _vm._s(item.end.slice(0, -3)) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(
                                      3,
                                      6
                                    ),
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start.slice(0, -3)) +
                                              "-" +
                                              _vm._s(item.end.slice(0, -3)) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.electricPriceVO.timeSharingVO.sharing.slice(
                                      6
                                    ),
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(
                                            _vm._s(item.start.slice(0, -3)) +
                                              "-" +
                                              _vm._s(item.end.slice(0, -3)) +
                                              "(" +
                                              _vm._s(
                                                _vm.electTypeList[item.type]
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "van-action-sheet",
              {
                staticClass: "indexDetail",
                attrs: { title: "水表详情", "close-icon": "cross" },
                model: {
                  value: _vm.waterDetailVis,
                  callback: function($$v) {
                    _vm.waterDetailVis = $$v
                  },
                  expression: "waterDetailVis"
                }
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("表")]),
                      _c("span", [_vm._v("计")]),
                      _c("span", [_vm._v("表")]),
                      _c("span", [_vm._v("号")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v(_vm._s(_vm.waterDetailMode.meterAddr))
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.waterDetailMode.roomName))]),
                      _c("p")
                    ])
                  ]),
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("当")]),
                      _c("span", [_vm._v("前")]),
                      _c("span", [_vm._v("行")]),
                      _c("span", [_vm._v("度")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", { staticClass: "blue" }, [
                        _vm._v(_vm._s(_vm.waterDetailMode.currentValue))
                      ]),
                      _vm._v(
                        "(" + _vm._s(_vm.waterDetailMode.currentReadTime) + ")"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "detailUnit" }, [
                    _c("div", { staticClass: "label" }, [
                      _c("span", [_vm._v("计")]),
                      _c("span", [_vm._v("价")]),
                      _c("span", [_vm._v("模")]),
                      _c("span", [_vm._v("式")])
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _vm.waterDetailMode.waterPriceVO
                        ? _c("span", { staticClass: "blue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.valuationWaterEnum[
                                  _vm.waterDetailMode.waterPriceVO.valuationKind
                                ]
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.waterDetailMode.waterPriceVO &&
                      _vm.waterDetailMode.waterPriceVO.valuationKind == 1
                        ? _c("span", { staticClass: "blue" }, [
                            _vm._v(
                              "（￥" +
                                _vm._s(
                                  _vm.waterDetailMode.waterPriceVO.fixedPriceVO
                                    .price
                                ) +
                                "）"
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm.waterDetailMode.waterPriceVO &&
                  _vm.waterDetailMode.waterPriceVO.valuationKind == 2
                    ? _c("div", { staticClass: "detailUnit" }, [
                        _c("div", { staticClass: "text sp" }, [
                          _vm.waterDetailMode.waterPriceVO.increasePriceVO
                            .increase.length < 4
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.waterDetailMode.waterPriceVO
                                      .increasePriceVO.increase,
                                    function(item, index) {
                                      return _c("td", { key: index }, [
                                        _c("span", { staticClass: "blocker" }, [
                                          _vm._v(_vm._s(item.start) + "-"),
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("span", [_vm._v("以上")])
                                            : _c("span", [
                                                _vm._v(_vm._s(item.end))
                                              ]),
                                          _c("span", { staticClass: "blue" }, [
                                            _vm._v(
                                              "(￥" +
                                                _vm._s(item.increasePrice) +
                                                ")"
                                            )
                                          ])
                                        ])
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.waterDetailMode.waterPriceVO.increasePriceVO
                            .increase.length > 3 &&
                          _vm.waterDetailMode.waterPriceVO.increasePriceVO
                            .increase.length < 7
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(
                                      0,
                                      3
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "td",
                                        { key: index },
                                        [
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-以上"
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-" +
                                                        _vm._s(item.end)
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(
                                      3,
                                      6
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "td",
                                        { key: index },
                                        [
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-以上"
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-" +
                                                        _vm._s(item.end)
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e(),
                          _vm.waterDetailMode.waterPriceVO.increasePriceVO
                            .increase.length > 6
                            ? _c("table", [
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(
                                      0,
                                      3
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "td",
                                        { key: index },
                                        [
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-以上"
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-" +
                                                        _vm._s(item.end)
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.electDetailMode.waterPriceVO.increasePriceVO.increase.slice(
                                      3,
                                      6
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "td",
                                        { key: index },
                                        [
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-以上"
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-" +
                                                        _vm._s(item.end)
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "tr",
                                  _vm._l(
                                    _vm.waterDetailMode.waterPriceVO.increasePriceVO.increase.slice(
                                      6
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "td",
                                        { key: index },
                                        [
                                          index ==
                                          _vm.waterDetailMode.waterPriceVO
                                            .increasePriceVO.increase.length -
                                            1
                                            ? _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-以上"
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("templete", [
                                                _c(
                                                  "span",
                                                  { staticClass: "blocker" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.start) +
                                                        "-" +
                                                        _vm._s(item.end)
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "blue" },
                                                      [
                                                        _vm._v(
                                                          "(￥" +
                                                            _vm._s(
                                                              item.increasePrice
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            )
          ]
        : [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  position: "fixed",
                  top: "200px",
                  left: "0",
                  right: "0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [_vm._v(" 页面加载中... ")]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleInfo" }, [
      _c("span", [_vm._v("表计列表")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "info" }, [_c("span", [_vm._v("当前余额")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleInfo pt43" }, [
      _c("span", [_vm._v("未缴账单")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goCharge" }, [
      _vm._v(" 前"),
      _c("br"),
      _vm._v("往"),
      _c("br"),
      _vm._v("缴"),
      _c("br"),
      _vm._v("费 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }